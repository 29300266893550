<template>
  <b-card
    no-body
    class="mb-0 mt-1"
  >
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Show</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label>entries</label>
        </b-col>

        <!-- Add Item -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Search..."
            />
          </div>
        </b-col>
      </b-row>
    </div>

    <b-table
      ref="refOrdiniListTable"
      class="position-relative mh-200"
      :items="fetchOrdini"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="Nessun ordine trovato"
      :sort-desc.sync="isSortDirDesc"
    >
      <!-- Column: Details -->
      <template #row-details="row">
        <b-card>

          <b-row class="mb-2">

            <b-col
              md="12"
            >
              <b-row>
                <b-col md="8">
                  <div class="ml-0">
                    <span class="d-block mb-1">Prodotti</span>
                    <b-list-group>
                      <b-list-group-item
                        v-for="item in row.item.items"
                        :key="item.id"
                        class="d-flex justify-content-between align-items-center"
                      >
                        <p>Cod. Prodotto: {{ item.code }}</p>
                        <p>Nome: {{ item.name }}</p>
                        <p>Prezzo: {{ item.pivot ? item.pivot.price : item.price }}</p>
                        <p>V.P.C.: {{ item.vpc }}</p>
                        <b-badge
                          variant="primary"
                          pill
                          class="badge-round"
                        >
                          {{ item.pivot ? item.pivot.quantity : item.quantity }}
                        </b-badge>
                      </b-list-group-item>
                    </b-list-group>
                  </div>
                </b-col>
              </b-row>
            </b-col>

            <b-col
              md="12"
              class="mt-1"
            >
              <b-row>
                <b-col md="8">
                  <div class="ml-0">
                    <span class="d-block mb-1">Indirizzi</span>
                    <b-list-group>
                      <b-list-group-item
                        v-for="address in row.item.addresses"
                        :key="address.id"
                        class="d-flex justify-content-between align-items-center"
                      >
                        <p>Tipo: {{ address.type }}</p>
                        <p>Via: {{ address.street }}</p>
                        <p>Numero: {{ address.number }}</p>
                        <p>Città: {{ address.city }}</p>
                        <p>Provincia: {{ address.province }}</p>
                        <p>CAP: {{ address.zip }}</p>
                      </b-list-group-item>
                    </b-list-group>
                  </div>
                </b-col>
              </b-row>
            </b-col>

          </b-row>

          <b-button
            size="sm"
            variant="outline-secondary"
            @click="row.toggleDetails"
          >
            Chiudi
          </b-button>
        </b-card>
      </template>

      <!-- Column: Code -->

      <template #cell(codice)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ data.item.code }}</span>
        </div>
      </template>

      <!--      <template #cell(stato)="data">-->
      <!--        <div class="text-nowrap">-->
      <!--          <b-badge-->
      <!--            :variant="data.item.active === 1 ? 'success' : 'warning'"-->
      <!--            class="badge"-->
      <!--          >-->
      <!--            {{ data.item.status }}-->
      <!--          </b-badge>-->
      <!--          <span class="align-text-top text-capitalize" />-->
      <!--        </div>-->
      <!--      </template>-->

      <template #cell(stato)="data">
        <div class="text-nowrap">

          <b-dropdown
            v-if="$can('update', 'Orders') && data.item.status !== 'Pending'"
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <b-badge
                :variant="statusResolver(data.item.status)"
                class="badge"
              >
                {{ statusNameResolver(data.item.status) }}
              </b-badge>
            </template>

            <b-dropdown-item
              v-if="data.item.status !== 'Sended'"
              @click="updateStatus(data.item, 'sended')"
            >
              <feather-icon icon="PackageIcon" />
              <span
                class="align-middle ml-50"
              >Spedito</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="data.item.status !== 'Payed'"
              @click="updateStatus(data.item, 'payed')"
            >
              <feather-icon icon="CheckIcon" />
              <span
                class="align-middle ml-50"
              >Pagato</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="data.item.status !== 'Payed' && data.item.status !== 'Sended' && data.item.payment_method !== 'Carta'"
              @click="updateStatus(data.item, 'to pay')"
            >
              <feather-icon icon="CreditCardIcon" />
              <span
                class="align-middle ml-50"
              >Da Pagare</span>
            </b-dropdown-item>
          </b-dropdown>

          <b-badge
            v-else
            :variant="statusResolver(data.item.status)"
            class="badge ml-1"
          >
            {{ statusNameResolver(data.item.status) }}
          </b-badge>
        </div>
      </template>

      <template #cell(incaricato)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ data.item.user.fullName }}</span>
          <span class="text-muted d-block">
            {{ data.item.user.code }}
          </span>
        </div>
      </template>

      <template #cell(tipo)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ data.item.type }}</span>
        </div>
      </template>

      <template #cell(mod_pagamento)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ data.item.payment_type }}</span>
        </div>
      </template>

      <template #cell(prezzo)="data">
        <div class="text-nowrap">
          <h5 class="align-text-top text-capitalize">
            {{ handlePriceText(data.item.total_price - data.item.discount) }}
          </h5>
        </div>
      </template>

      <template #cell(prezzo_listino)="data">
        <div class="text-nowrap">
          <h5 class="align-text-top text-capitalize">
            {{ handlePriceText(data.item.total_price) }}
          </h5>
        </div>
      </template>

      <template #cell(sconto)="data">
        <div class="text-nowrap">
          <h5 class="align-text-top text-capitalize">
            {{ handlePriceText(data.item.discount) }}
          </h5>
        </div>
      </template>

      <template #cell(vpc)="data">
        <div class="text-nowrap">
          <h5 class="align-text-top text-capitalize">
            {{ data.item.total_vpc }}
          </h5>
        </div>
      </template>

      <template #cell(creato_il)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ data.item.created_at }}</span>
        </div>
      </template>

      <template #cell(aggiornato_il)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ data.item.updated_at }}</span>
        </div>
      </template>

      <template #cell(pagato_il)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ data.item.payed_at }}</span>
        </div>
      </template>

      <!-- Column: Actions -->
      <template #cell(azioni)="data">
        <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >

          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>

          <b-dropdown-item @click="data.toggleDetails">
            <feather-icon icon="EyeIcon" />
            <span
              class="align-middle ml-50"
            >Dettagli</span>
          </b-dropdown-item>

          <!--          <b-dropdown-item-->
          <!--            v-if="$can('update', 'Orders')"-->
          <!--            @click="$bvModal.show('modal-edit-user-'+data.item.id)"-->
          <!--          >-->
          <!--            <feather-icon icon="EditIcon" />-->
          <!--            <span-->
          <!--              class="align-middle ml-50"-->
          <!--            >Modifica</span>-->
          <!--          </b-dropdown-item>-->

          <!--          <b-dropdown-item-->
          <!--            v-if="$can('destroy', 'Orders') && !data.item.disabled"-->
          <!--            ref="swalConfirmDeleteIscritto"-->
          <!--            @click="swalConfirmDeleteIscritto(data.item.id)"-->
          <!--          >-->
          <!--            <feather-icon icon="TrashIcon" />-->
          <!--            <span class="align-middle ml-50">Cancella</span>-->
          <!--          </b-dropdown-item>-->

        </b-dropdown>
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalOrders"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormInput,
  BButton,
  BBadge,
  BListGroup,
  BListGroupItem,
  // BLink,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import useOrderList from '@/views/ordini/useOrderList'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'OrdersTable',
  components: {
    // NewItem,
    BCard,
    BRow,
    BCol,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormInput,
    BButton,
    BBadge,
    vSelect,
    BListGroup,
    BListGroupItem,
  },
  directives: {
    Ripple,
  },
  props: {},
  watch: {},
  mounted() {
  },
  setup() {
    const toast = useToast()

    const {
      // Options
      typeOptions,
      statusOptions,
      siNoOptions,
      //
      fetchOrdini,
      refetchOrdine,
      getListOrdini,
      tableColumns,
      perPage,
      currentPage,
      totalOrders,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refOrdiniListTable,
      refetchData,

      // Extra Filters
      //
      statusResolver,
      statusNameResolver,
      handlePriceText,
    } = useOrderList()

    const updateStatus = (item, newStatus) => {
      store.dispatch('orders/updateOrdine', {
        id: item.id,
        status: newStatus,
      })
        .then(response => {
          refetchData()
          toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          console.log(error.response)
          toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.code ? error.response.data.code[0] : error.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    return {
      // Options
      typeOptions,
      statusOptions,
      siNoOptions,
      //
      fetchOrdini,
      refetchOrdine,
      getListOrdini,
      tableColumns,
      perPage,
      currentPage,
      totalOrders,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refOrdiniListTable,
      refetchData,

      // Extra Filters
      //
      statusResolver,
      statusNameResolver,
      handlePriceText,

      updateStatus,
    }
  },
  methods: {
    swalConfirmDeleteIscritto(id) {
      this.$swal({
        title: 'Sei sicura/o?',
        text: 'Non potrai annullare questa operazione',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si cancella!',
        cancelButtonText: 'Chiudi',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            store.dispatch('users/forceDeleteIscritto', id)
              .then(() => {
                this.refetchData()
                this.$swal({
                  icon: 'success',
                  title: 'Cancellato!',
                  text: 'Utente cancellato',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              })
          } else if (result.dismiss === 'cancel') {
            this.$swal({
              title: 'Cancelled',
              text: 'Cancellazione annullata',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
          return result.value
        })
    },
    swalConfirmRestoreIscritto(id) {
      this.$swal({
        title: 'Sei sicura/o?',
        text: 'Non potrai annullare questa operazione',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ripristina!',
        cancelButtonText: 'Chiudi',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            store.dispatch('users/forceDeleteIscritto', id)
              .then(() => {
                this.refetchData()
                this.$swal({
                  icon: 'success',
                  title: 'Ripristinato!',
                  text: 'Utente ripristinato',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              })
          } else if (result.dismiss === 'cancel') {
            this.$swal({
              title: 'Cancelled',
              text: 'Ripristino annullato',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
          return result.value
        })
    },
  },
}
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped/>
