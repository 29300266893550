import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'

export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refOrdiniListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'codice',
      value: 'code',
      sortable: true,
    },
    {
      key: 'stato',
      value: 'status',
      sortable: true,
    },
    {
      key: 'incaricato',
      value: 'user',
      sortable: false,
    },
    {
      key: 'tipo',
      value: 'type',
      sortable: false,
    },
    // { key: 'cognome', value: 'last_name', sortable: false },
    {
      key: 'mod_pagamento',
      value: 'payment_type',
      sortable: true,
    },
    {
      key: 'prezzo',
      value: 'total_price',
      sortable: true,
    },
    {
      key: 'prezzo_listino',
      value: 'total_price',
      sortable: true,
    },
    {
      key: 'sconto',
      value: 'discount',
      sortable: true,
    },
    {
      key: 'vpc',
      value: 'total_vpc',
      sortable: true,
    },
    {
      key: 'creato_il',
      value: 'created_at',
      sortable: true,
    },
    {
      key: 'aggiornato_il',
      value: 'updated_at',
      sortable: true,
    },
    {
      key: 'pagato_il',
      value: 'payed_at',
      sortable: true,
    },
    { key: 'azioni' },
  ]

  const typeOptions = [
    {
      label: 'Incaricato',
      value: 'incaricato',
    },
    {
      label: 'Cliente',
      value: 'cliente',
    },
  ]

  const statusOptions = [
    {
      label: 'Attivo',
      value: 1,
    },
    {
      label: 'Inattivo',
      value: 0,
    },
  ]

  const siNoOptions = [
    {
      label: 'Si',
      value: 1,
    },
    {
      label: 'No',
      value: 0,
    },
  ]

  const macchinaOptions = [
    {
      label: 'Base',
      value: 'base',
    },
    {
      label: 'RDL',
      value: 'rdl',
    },
    {
      label: 'HO.RE.CA',
      value: 'horeca',
    },
  ]
  const quantityOptions = [1, 2, 3, 4, 5, 10]
  const caffeOptions = [
    {
      label: 'Forte',
      value: 'forte',
      price_id: process.env.VUE_APP_STRIPE_COFFE_PRICE_3,
    },
    {
      label: 'Decaffeinato',
      value: 'decaffeinato',
      price_id: process.env.VUE_APP_STRIPE_COFFE_PRICE_4,
    },
    {
      label: 'Dolce',
      value: 'dolce',
      price_id: process.env.VUE_APP_STRIPE_COFFE_PRICE_4,
    },
    {
      label: 'Orzo',
      value: 'orzo',
      price_id: process.env.VUE_APP_STRIPE_COFFE_PRICE_4,
    },
  ]

  const perPage = ref(25)
  const totalOrders = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localUsersCount = refOrdiniListTable.value ? refOrdiniListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localUsersCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localUsersCount,
      of: totalOrders.value,
    }
  })

  const fetchOrdini = (ctx, callback) => {
    let sorterResolved = sortBy.value
    if (sorterResolved !== 'id' && tableColumns.find(key => key.key === sorterResolved).value !== sorterResolved) {
      sorterResolved = tableColumns.find(key => key.key === sorterResolved).value
    }
    store
      .dispatch('orders/fetchOrdini', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sorterResolved,
        sortDesc: isSortDirDesc.value,
      })
      .then(response => {
        const { orders, meta } = response.data.data
        if (callback) {
          callback(orders)
        }
        totalOrders.value = meta.total
      })
      .catch(error => {
        console.log(error.response)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Problemi nel caricamento ordini',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const refetchData = () => {
    if (refOrdiniListTable.value) {
      refOrdiniListTable.value.refresh()
    } else {
      fetchOrdini()
    }
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const refetchOrdine = () => {
    store.dispatch('orders/fetchOrdine', { id: router.currentRoute.params.id })
      .then(() => {
      })
      .catch(error => {
        console.log(error.response)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Problemi nel caricamento utenti',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const getListOrdini = () => store.getters['orders/getListOrdini']

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const statusResolver = currentStatus => {
    if (currentStatus === 'Pending') return 'secondary'
    if (currentStatus === 'To Pay') return 'warning'
    if (currentStatus === 'Payed') return 'light-success'
    if (currentStatus === 'Sended') return 'success'
    return 'secondary' // Se archived
  }

  const statusNameResolver = currentStatusName => {
    if (currentStatusName === 'Pending') return 'In Sospeso'
    if (currentStatusName === 'To Pay') return 'Da Pagare'
    if (currentStatusName === 'Payed') return 'Pagato'
    if (currentStatusName === 'Sended') return 'Spedito'
    return 'secondary' // Se archived
  }

  const handlePriceText = price => {
    if (price) {
      return `€ ${price}`
    }
    return '€0'
  }

  return {
    // Options
    typeOptions,
    statusOptions,
    siNoOptions,
    macchinaOptions,
    quantityOptions,
    caffeOptions,
    //
    fetchOrdini,
    refetchOrdine,
    getListOrdini,
    tableColumns,
    perPage,
    currentPage,
    totalOrders,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refOrdiniListTable,
    refetchData,

    // Extra Filters
    //
    statusResolver,
    statusNameResolver,
    handlePriceText,

  }
}
